<template>
  <div
    class="flex min-h-screen flex-col justify-between max-lg:bg-gray-10 lg:w-full"
  >
    <nav role="navigation" class="fixed z-40 w-screen lg:top-4">
      <Container indent="none" class="w-full">
        <div class="flex items-center justify-between bg-black p-4">
          <div class="flex items-center gap-10">
            <NuxtLink :to="localePath('/')">
              <Logo class="h-8" white />
            </NuxtLink>
            <AppLink
              :to="getShopRoute('/checkout/cart')"
              :underline="false"
              icon="arrow-left"
              icon-classes="w-5 mr-1"
              class="text-white hover:text-white"
            >
              {{ $t('checkout.backToShop') }}
            </AppLink>
          </div>
          <ShopSharedCart class="max-lg:hidden" />
        </div>
        <Notifications class="mt-2 max-md:hidden" />
      </Container>
      <Notifications class="lg:hidden" />
    </nav>

    <main class="flex-1 pt-20 lg:pt-28">
      <ClientOnly>
        <slot />
      </ClientOnly>
    </main>

    <AppFooter />

    <BaseBreaky />
  </div>
</template>

<script setup lang="ts">
const localePath = useLocalePath()
const { getShopRoute } = useShopRouter()
</script>
